<template>
  <div v-if="productsVO">
    <div class="product-wrap">
      <div class="product-pic"  @click="toggleShow">
        <video  id="player-container-id"
                v-if="videoFileId"
                poster="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/PIC/152893_pic.jpg"
                playsinline
                webkit-playinline
                x5-playinline
                x-webkit-airplay="true" webkit-playsinline="true"

        ></video>
        <img :src="productsVO.pic"   v-if="shopShow" style="position: absolute;top: 1.46667rem;" id="showPic" ref="showPic" >
      </div>
      <div class="product-catno">{{productsVO.catno}}</div>
      <div class="product-info">
        <div class="product-name">{{ productsVO.cnname.replace('‘','\'').replace('’','\'') }}</div>
        <div class="product_original_price" v-if="biaowuPriceOpen && biaowuPriceOpen == '1' && isLogin &&  productsVO.suppliercode == 1">原价：{{originalPrice}}</div>
        <div class="product-price" v-if="productPrice && biaowuPriceOpen && biaowuPriceOpen == '1'">￥{{productPrice}}</div>
      </div>
      <div class="product-detail" v-if="productLotList&&productLotList.length>1">
        <div class="title">规格选择</div>
        <div class="product-guige">
          <span  class="product-specifications" v-for="(item, index) in productLotList" :key="index"  :class="item.specChecked?'product-active':''"  @click="clickSpec(item, index)">{{item.spec}}<template v-if="item.nongdu">,</template>{{item.nongdu}}</span>
        </div>
      </div>
      <div class="product-detail" v-else>
        <div class="title">规格选择</div>
        <div class="product-guige">
          <span  class="product-specifications product-active">{{productsVO.specification}} <span v-if="productsVO.nongdu">,</span> {{productsVO.nongdu}}</span>
        </div>
      </div>

      <div class="product-detail" v-if="productsVO.suppliercode==1">
        <div class="title">产品证书</div>
        <div class="product-guige">
          <span  class=" product-specifications product-certificate"   @click="downloadcertificate()">下载证书</span>
        </div>
      </div>

      <!--        弹窗-->
      <div class="modal1" id="modal1" v-if="showModal">
        <div class="modal-backdrop"></div>
        <div class="modal-body">
          <div class="modal-body-title">请输入标签批号</div>
          <div class="like-user-search" >
            <input type="text" placeholder="标签批号" v-model="batchNo">
          </div>
          <div class="coupon-button">
            <span class="coupon_btn" @click="download()">下载</span>
            <span class="coupon_btn" style="background: #999" @click="closeModal()">取消</span>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <div class="product-detail">
        <div class="title">商品详情</div>
        <div class="content">
          <table border="1" cellspacing="0" cellpadding="10.013px">
            <thead>
            <tr>
              <th colspan="2">基本信息</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="productsVO.suppliercode==1 || productsVO.suppliercode==22">
              <td class="name">品牌</td>
              <td><span class="highlight">伟业计量</span></td>
            </tr>
            <tr v-if="productsVO.supplier == 'BNCC'">
              <td class="name">品牌</td>
              <td><span class="highlight">BNCC</span></td>
            </tr>

            <tr>
              <template v-if="productsVO.stock">
                <td class="name">库存</td>
                <td v-if="productStock>9">≥10</td>
                <td v-else>{{productStock}}</td>
              </template>
              <template v-else>
                <td class="name">货期</td>
                <td v-if="productsVO.specialDelivery && productsVO.specialDelivery!=''">{{productsVO.specialDelivery}}</td>
                <td v-else-if="productsVO.catno && productsVO.catno.includes('SHAM')">一周</td>
                <td v-else-if="productsVO.catno && productsVO.catno.includes('BWS')">两周</td>
                <td v-else-if="productsVO.suppliercode==1">3天</td>
                <td v-else>一周</td>
              </template>
            </tr>
            <tr v-if="productsVO.shelfLife && productsVO.shelfLife!=0">
              <td class="name">有效期</td>
              <td>{{productsVO.shelfLife}}个月</td>
            </tr>
            <tr v-if="productsVO.enname">
              <td class="name">英文名称</td>
              <td v-html="productsVO.enname"></td>
            </tr>

            <tr v-if="productsVO.casno">
              <td class="name">CAS</td>
              <td>{{ productsVO.casno }}</td>
            </tr>
            <tr v-if="productsVO.purity">
              <td class="name">纯度</td>
              <td>{{ productsVO.purity }}</td>
            </tr>
            <tr v-if="productsVO.batch">
              <td class="name">批次</td>
              <td>{{ productsVO.batch }}</td>
            </tr>

            <tr v-if="productsVO.statu">
              <td class="name">形态</td>
              <td>{{ productsVO.statu }}</td>
            </tr>

            <tr v-if="productsVO.stroma">
              <td class="name">基质</td>
              <td>{{ productsVO.stroma }}</td>
            </tr>

            <tr v-if="productsVO.storageCondition">
              <td class="name">存储条件</td>
              <td>{{ productsVO.storageCondition }}</td>
            </tr>

            <tr v-if="productsVO.applications">
              <td class="name">用途</td>
              <td>{{ productsVO.applications }}</td>
            </tr>
            <tr v-else-if="productsVO.usedfor">
              <td class="name">用途</td>
              <td>{{ productsVO.usedfor }}</td>
            </tr>
            <tr v-if="productsVO.packing">
              <td class="name">包装</td>
              <td>{{ productsVO.packing }}</td>
            </tr>

            <tr v-if="productsVO.notice">
              <td class="name">注意事项</td>
              <td>{{ productsVO.notice }}</td>
            </tr>

            <tr v-if="productsVO.reference">
              <td class="name">参考资料</td>
              <td>{{ productsVO.reference }}</td>
            </tr>

            <tr v-if="productsVO.alias">
              <td class="name">别名</td>
              <td>{{ productsVO.alias }}</td>
            </tr>

            <tr v-if="productsVO.physicochemicalProperty">
              <td class="name">理化性质</td>
              <td>{{ productsVO.physicochemicalProperty }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="title title-top">量值信息</div>
        <div class="content">
          <table border="1" cellspacing="0" cellpadding="10.013px">
            <thead style="background-color: #5f5f5f; border-color: #5f5f5f">
            <tr>
              <th colspan="4">量值信息</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td >序号</td>
              <td class="name">组分</td>
              <td>标准值</td>
              <td>不确定度</td>
            </tr>
            <tr v-for="item in list" :key="item">
              <td v-if="item.teamCount>0" :rowspan='item.teamCount'>{{item.team }}</td>
              <td v-else style="display:none;"></td>
              <td class="name" v-html="item.components"></td>
              <td>{{ item.concentration }}</td>
              <td>{{ item.uncertainty }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="other-view" v-if="productsVO.suppliercode == 1">
        <div class="title">产品详情：</div>
        <div class="content">
          <div class="detail-title">一、样品制备</div>
          <div class="detail-content" v-if="productsVO.preparation">
            {{ productsVO.preparation }}
          </div>
          <template
                  v-if="
              productsVO.traceability &&
              productsVO.traceability.indexOf('稀释后方可使用') != -1
            "
          >
            <div class="detail-title">二、稀释方法</div>
            <div class="detail-content">{{ productsVO.traceability }}}</div>
            <div class="detail-title">三、稀释后特性量值及扩展不确定度</div>
          </template>
          <template v-else>
            <div class="detail-title">二、溯源性及定值方法</div>
            <div class="detail-content">{{ productsVO.traceability }}</div>
            <div class="detail-title">三、特征量值及扩展不确定度</div>
          </template>
          <table border="1" cellspacing="0" cellpadding="10.013px" v-if="comp">
            <thead>
            <tr>
              <th>序号</th>
              <th class="detail-name">组分</th>
              <th>标准值</th>
              <th>不确定度</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list" :key="item">
              <td v-if="item.teamCount>0" :rowspan='item.teamCount'>{{item.team }}</td>
              <td v-else style="display:none;"></td>
              <td class="name" v-html="item.components"></td>
              <td>{{ item.concentration }}</td>
              <td>{{ item.uncertainty }}</td>
            </tr>
            <!--<tr>
              <td class="detail-name">{{ comp.components }}</td>
              <td>{{ comp.concentration }}</td>
              <td>{{ comp.uncertainty }}</td>
            </tr>
            <tr v-for="item in secListComp" :key="item">
              <td class="detail-name">{{ item.components }}</td>
              <td>{{ item.concentration }}</td>
              <td>{{ item.uncertainty }}</td>
            </tr>-->
            
            </tbody>
          </table>
          <div class="detail-content">
            <span v-if="productsVO.uncertaintySource"
            >标准值的扩展不确定度{{ productsVO.uncertaintySource }}</span
            >
            <span v-else
            >标准值的扩展不确定度主要由原料纯度、定容等引入的不确定度合成。</span
            >
          </div>

          <div class="detail-title">四、均匀性检验及稳定性考察</div>
          <div class="detail-content">
            <span v-if="amclass">
              参照JJF1343 国家计量技术规范（等效ISO指南35），对分装后的样品进行随机抽样，采用{{amclass.dictValue}}
              对该标准物质进行均匀性检验和长期稳定性跟踪考察。结果表明：均匀性符合F检验规则，稳定性考察良好。
            </span>
            本标准物质量值自定值之日起，有效期{{productsVO.shelfLife }}月,研制单位将继续跟踪监测该标准物质的稳定性，有效期内如发现量值变化，将及时通知用户。
          </div>
          <div class="detail-title">五、包装、储存及使用</div>
          <div class="detail-content">
            包装:本标准物质采用{{ productsVO.packing }}包装,规格{{
            productsVO.specification
            }}携带或运输时应有防碎裂保护。 储存及使用:{{
            productsVO.storageCondition
            }}
          </div>
          <div class="pdt_detail_box">
            <p class="pdt_detail_title">声明</p>
            <p>
              1.
              本标准物质仅供实验室研究与分析测试工作使用。因用户使用或储存不当所引起的投诉，不予承担责任。
            </p>
            <p>
              2.
              收到后请立即核对品种、数量和包装，相关赔偿只限于标准物质本身，不涉及其他任何损失。
            </p>
            <p>
              3.
              仅对加盖“北方伟业计量集团有限公司标准物质专用章”的完整证书负责。请妥善保管此证书。
            </p>
            <p style>4. 如需获得更多与应用有关的信息，请与技术咨询部门联系。</p>
            <div class="footer-info" v-if="productsVO.suppliercode==1">
              <div class="info-left">
                <img src="@/assets/BarCode.png" alt />
              </div>
              <div class="info-right">
                <p>北方伟业计量集团有限公司</p>
                <p>地址：北京市朝阳区汤立路216号</p>

                <p>技术咨询：400-999-3855</p>
              </div>
              <div style="font-size: 14px;margin-top: 10px;"><span style="color: red;">*</span> 注：以上信息仅供参考，以产品附带证书为准。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrong-view" v-if="productPdfHref && productPdfHref.certificateTxt">
        <div class="title">查看证书</div>
        <div class="wrong-content" v-html="productPdfHref.certificateTxt"></div>
        <div style="font-size: 14px;margin-top: 5px;">
          <span style="color: red;">*</span>
          注：以上信息仅供参考，以产品附带证书为准。
        </div>
      </div>
      <div class="recommend-title" v-if="standardList && standardList.length>0">研制依据：</div>
      <div class="recommend recommend_standard " v-if="standardList && standardList.length>0">
        <div class="content">
          <ul>
            <li v-for="item in standardList" :key="item">
              <router-link :to="{name: 'standarddetail', params: { id: item.id, classId: item.standardType }}"
                           v-if="item.allName" v-html="item.allName">
              </router-link>
              <a href="javascript:;" v-else>
                {{item.reStandardNo}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="recommend-title">相关产品：</div>
      <div class="recommend">
        <div class="content">
          <product-card
                  :list="{ data: RelatedProIdList }"
                  name="productdetail"
                  :upParam="biaowuPriceOpen"
                  tid="0">
          </product-card>
        </div>
      </div>
    </div>
    <slot-footer :pid="id" tid="0" nclassId="0" type="1" :isFav="isFav" :lotid=lotid :tcid=0 :stock="productsVO.stock"
                 :viewCartText="isApplyOpen != 1?'免费试用':'查看购物车'" v-model:buttonType.sync="isApplyOpen"></slot-footer>
  </div>
  <loading :options="loadOption" v-if="!productsVO && loadOptionSwitch"></loading>
</template>

<script lang="ts">
  import {defineComponent, ref, reactive, watch, inject, onMounted, nextTick, onUnmounted} from "vue";
  import { useRoute, useRouter } from "vue-router";
  import api from "@/api/axios";
  import wx from "weixin-sdk-js";
  import axios from "@/api/axios";
  import useFootPrint from "@/hooks/useFootPrint";
  import SlotFooter from "@/components/common/SlotFooter.vue";
  import Loading from "@/components/UI/Loading";
  import navigatorType from "@/utils/browser";
  import asyncLoadJs from "@/hooks/useAsyncLoadJs";
  import productCard from "@/components/common/productCard.vue";
  import vTCPlayer from "@/hooks/useTcplayer";
  import calendarFormat from "@/utils/dateFormat";
  import Toast from "@/components/UI/Toast/";
  import qs from "qs";
  import {IproductList, productMap} from "@/hooks/useProduct";


  export default defineComponent({
    name: "productdetail",
    components: {
      SlotFooter,
      Loading,
      productCard
    },
    setup() {
      const shareTitle = ref('')
      const loadOptionSwitch = ref(true);
      const route = useRoute();
      const router = useRouter();
      const id = ref(route.params.id);
      const classId = ref(route.params.classId);
      const isFav = ref(false);
      const productsVO = ref(null);
      const list = ref([]);
      const secListComp = ref([]);
      const amclass = ref(null);
      const catNo = ref("");
      const suppliercode = ref("");
      const comp = ref({});
      // const RelatedProIdList = ref([]);
      let RelatedProIdList = reactive<object[]>([]);

      const standardList = ref([]);
      const { setFootItem } = useFootPrint();
      const listCompLen = ref(0);
      const  productLotList = ref()
      const lotid = ref()
      const tcid = ref(0)
      const specChecked = ref(true)
      const productPrice = ref()
      const isLogin = ref()
      const originalPrice = ref()
      const productStock = ref()
      const biaowuPriceOpen = ref()
      const isApplyOpen = ref()
      const productPdfHref = ref()
      //  展示用户信息
      const userInfo = inject("userInfo") as any;
      let VideoPlayer: any = null;
      const videoFileId = ref();
      const shopShow = ref(true)
      const showModal =ref(false)
      const batchNo = ref("");
      const destoryKeepAlive = inject(
              "destoryKeepAlive"
      ) as () => Promise<unknown>;

      function clickSpec(item: any, index: any) {
        productStock.value = item.stock
        lotid.value = item.lotid;

        productLotList.value.length>1 && productLotList.value.forEach((item: any) => {
          item.specChecked = false
        })
        productLotList.value[index].specChecked = true
        specChecked.value = false

        // 判断是否登录
        if (userInfo) {
          if ((productsVO.value as any).suppliercode == 1) {
            productPrice.value = Math.ceil(Number(item.outPrice)*(userInfo.discount?userInfo.discount:1))
          } else {
            productPrice.value = Math.ceil(Number(item.outPrice))
          }
        } else {
          productPrice.value = Math.ceil(Number(item.outPrice))
        }

      }
      function firstSpec() {
        if(!productLotList.value[0]) {
          return false
        }
        lotid.value = productLotList.value[0].lotid
        specChecked.value = true
        productLotList.value.length>0 && productLotList.value.forEach((item: any) => {
          item.specChecked = false
        })
      }
      function closeModal(){
        showModal.value = false;
      }
      //校验批次号
      function checkBatchNum(batchNum: string) {
        const reg = /^[0-9]{8}$/;
        const reg2 = /^[0-9]{10}$/;
        console.log("batchNum:",batchNum);
        if (batchNum && !(reg.test(batchNum)||reg2.test(batchNum))) {
          Toast({
            type: "warning",
            title: "请输入正确的批次号",
          });
          return false;
        }
        return true;
      }
      function checkYear(year: string) {
        console.log("年份：",year);
        if (isNaN(parseInt(year))) {
          Toast({
            type: "warning",
            title: "标签批号年份输入有误,请重新输入！",
          });
          return false;
        }
        const now = new Date();
        const currentYear = now.getFullYear();
        const lastFiveYear = currentYear-5;
        console.log(lastFiveYear+"--"+currentYear);
        if (parseInt(year) < lastFiveYear || parseInt(year) > currentYear) {
          Toast({
            type: "warning",
            title: "标签批号年份应该在"+lastFiveYear+"-"+currentYear+"之间！",
          });
          return false;
        }
        return true;
      }

      function checkMonth(month: string) {
        if (isNaN(parseInt(month, 10))) {
          Toast({
            type: "warning",
            title: "标签批号月份输入有误,请重新输入！",
          });
          return false;
        } else if (parseInt(month, 10) < 1 || parseInt(month, 10) > 12) {
          Toast({
            type: "warning",
            title: "标签批号月份应该在1-12之间！",
          });
          return false;
        }
        return true;
      }

      function checkDay(day: string) {
        if (isNaN(parseInt(day, 10))) {
          Toast({
            type: "warning",
            title: "标签批号日期输入有误,请重新输入！",
          });
          return false;
        } else if (parseInt(day, 10) < 1 || parseInt(day, 10) > 31) {
          Toast({
            type: "warning",
            title: "标签批号日期应该在1-31之间！",
          });
          return false;
        }
        return true;
      }

      function checkPlanNo(planNo: string) {
        console.log("计划号：",planNo);
        if(isNaN(parseInt(planNo))){
          Toast({
            type: "warning",
            title: "标签批号输入有误,请重新输入！",
          });
          return false;
        }
        return true;
      }
      function downloadCert(productNum: string,batchNum: string) {
        //组装参数
        const data = qs.stringify({
          productNum: productNum,
          batchNum: batchNum,
        });
        //请求下载证书
        axios.post("/M/Product/checkProductCert", data,{})
                .then((res) => {
                  console.log("res:",res)
                  if (res.data.success) {
                    //下载证书
                    const link = document.createElement('a');
                    link.href = res.data.link;
                    link.setAttribute('download', res.data.fileName);
                    link.click();
                    //关闭弹窗
                    //showModal.value = false;
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.message || err
                  });
                });
      }
      function download(){
        console.log("batchNo:",batchNo.value)
        //校验参数
        if(!batchNo.value||batchNo.value==""){
          Toast({
            type: "warning",
            title: "请输入正确的批次号",
          });
          return false;
        }
        if (!checkBatchNum(batchNo.value)) {
          return false;
        }

        const years = batchNo.value.substring(0, 4);
        const moneys = batchNo.value.substring(4, 6);
        const days = batchNo.value.substring(6, 8);
        if (batchNo.value.length==10&&checkYear(years) && checkMonth(moneys) && checkDay(days) && checkPlanNo(batchNo.value.substring(8, 10))) {
          //下载证书
          downloadCert(catNo.value,batchNo.value);
          showModal.value = false;
        }
        if (batchNo.value.length==8&&checkYear(years) && checkMonth(moneys) && checkDay(days)) {
          //下载证书
          downloadCert(catNo.value,batchNo.value);
          showModal.value = false;
        }
      }
      function  downloadcertificate(){
        // console.log(showModal.value,1111)
        if ((productsVO.value as any).suppliercode == 1) {//伟业产品
          showModal.value = true;
        }else{
          //非伟业产品
          downloadCert(catNo.value,batchNo.value);
          showModal.value = false;
        }
      }

      function getData(id: any) {
        productsVO.value = null;
        RelatedProIdList=reactive<object[]>([]);
        api
                .get("/M/Product/ProductDetail/" + id)
                .then((res): void => {
                  if(res.data.success){
                    loadOptionSwitch.value = true;
                    const data = res.data.obj;
                    isApplyOpen.value = data.isApplyOpen;
                    if(!isApplyOpen.value){
                      isApplyOpen.value =0;
                    }
                    biaowuPriceOpen.value = data.biaowuPriceOpen;
                    if (data.productsVO.nongdu && data.productsVO.nongdu.indexOf('(') != -1) {
                      const index = data.productsVO.nongdu.indexOf('(')
                      data.productsVO.nongdu = data.productsVO.nongdu.substring(0, index)
                    }
                    productsVO.value = data.productsVO;
                    productPdfHref.value = data.productPdfHref
                    document.title = data.productsVO.cnname + "-伟业计量";
                    shareTitle.value = data.productsVO.cnname
                    route.meta.title = data.productsVO.cnname
                    route.meta.content.description = data.description;
                    list.value = data.listComp;
                    comp.value = data.comp;
                    isFav.value = data.isFav;
                    amclass.value = data.amclass;
                    catNo.value = data.productsVO.catno;
                    suppliercode.value = data.productsVO.suppliercode;
                    const pcStr = res.data.str;
                    if(pcStr){
                      //返回了产品标签批次号，就触发下载证书事件
                      showModal.value = true;
                      batchNo.value =pcStr;
                      if(suppliercode.value && suppliercode.value =='1'){
                        //伟业
                        download();
                      }else{
                        //非伟业产品
                        downloadCert(catNo.value,batchNo.value);
                      }
                      // downloadcertificate();
                    }

                    data.RelatedProIdList.forEach((item: IproductList) => {
                      RelatedProIdList.push(productMap(item));
                    });

                    standardList.value = data.standardList;
                    secListComp.value = data.secListComp;
                    listCompLen.value = data.listComp.length;
                    data.productLotList.length>1 && data.productLotList.forEach((item: any) => {
                      item.specChecked = false
                    })
                    if(data.productLotList.length>1){
                      data.productLotList[0].specChecked = true;
                      lotid.value = data.productLotList[0].lotid
                    }
                    productLotList.value = data.productLotList
                    const isPc = navigatorType();
                    if (isPc) {
                      window.location.href = `https://www.bzwz.com/p_${data.productsVO.classId}/p_${data.productsVO.id}.html`;
                    }
                    if(data.productVideoId){
                      videoFileId.value =data.productVideoId;
                    }else{
                      videoFileId.value ="5576678019462733897";
                    }
                    nextTick(() => {
                      asyncLoadJs(
                              "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
                              "ProductDetail"
                      ).then(() => {
                        asyncLoadJs(
                                "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
                                "ProductDetail"
                        ).then((res) => {
                          VideoPlayer = vTCPlayer(
                                  "player-container-id",
                                  videoFileId.value,
                                  false
                          );
                          VideoPlayer.one('play',function () {
                            shopShow.value = false;
                          });
                        });
                      });
                    })

                    // 判断价格
                    // 判断是否登录
                    const loginUser = data.loginUser;
                    isLogin.value = data.loginUser
                    //add 20230220 手机端未登录不展示价格
                    if(!loginUser || !loginUser.id){
                      biaowuPriceOpen.value=0;
                    }
                    if (productLotList.value.length>1) {
                      productStock.value = data.productLotList[0].stock
                      if (data.productsVO.suppliercode == 1) {
                        if (loginUser) {
                          productPrice.value = Math.ceil(Number(data.productLotList[0].outPrice)*(loginUser.discount?loginUser.discount:1))
                          originalPrice.value = Math.ceil(Number((data.productLotList[0].outPrice)))
                        } else {
                          productPrice.value = Math.ceil(Number((data.productLotList[0].outPrice)))
                        }
                      }else{
                        productPrice.value = Math.ceil(Number(data.productLotList[0].outPrice))
                      }

                    }else{
                      productStock.value = (productsVO.value as any).stock
                      if (data.productsVO.suppliercode == 1) {
                        if (loginUser) {
                          productPrice.value =  Math.ceil(Number((productsVO.value as any).retailPrice)*(loginUser.discount?loginUser.discount:1))
                          originalPrice.value = Math.ceil(Number((productsVO.value as any).retailPrice))
                        } else {
                          productPrice.value = Math.ceil(Number((productsVO.value as any).retailPrice))
                        }
                      }else{
                        productPrice.value = Math.ceil(Number((productsVO.value as any).retailPrice))
                      }
                    }

                    /**
                     *  存足迹
                     */
                    setFootItem({
                      type: "product",
                      img: (productsVO.value as any).switchPic,
                      title: (productsVO.value as any).cnname,
                      info: (productsVO.value as any).catno,
                      proid: (productsVO.value as any).id,
                      brand: (productsVO.value as any).suppliercode
                              ? "标准值" + (productsVO.value as any).suppliercode + "组分"
                              : (productsVO.value as any).suppliercode,
                      specification: (productsVO.value as any).specification,
                      concentration: (productsVO.value as any).concentration,
                      url: { name: "productdetail", params: { id: route.query.id } },
                    });
                  }else{
                    loadOptionSwitch.value = false;
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 3000,
                    });

                  }

                })
                .catch((err) => {
                  console.log(err);
                });
      }
      getData(id.value);

      watch(
              [
                () => {
                  return route.query;
                },
                () => {
                  return route.params;
                },
              ],
              (to, from) => {
                // 避免登录时触发页面刷新
                console.log(to, from);
                if (to[1].id && !from[1].valid && !from[1].validate) {
                  VideoPlayer.dispose()
                  videoFileId.value = "";
                  destoryKeepAlive().then(() => {
                    getData(to[1].id as string);
                  });
                }
              }
      );
      onUnmounted(() => {
        VideoPlayer.dispose()
        videoFileId.value = "";
      })
      watch(
              () => {
                return shareTitle.value
              },
              (to, from) => {
                const isweixin = ref(false);
                const ua = navigator.userAgent.toLowerCase();
                const reg = /MicroMessenger/i;
                isweixin.value = reg.test(ua);
                if (isweixin.value) {
                  nextTick(() => {
                    // const imgUrl = "http://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
                    const link = window.location.href;
                    const title = document.title;
                    const desc = (document.querySelectorAll(
                            "meta[name=description]"
                    )[0] as any).content;
                    console.log(desc);

                    const formData = new FormData();
                    formData.append("url", link);
                    axios.post("/M/Server/getweixin", formData).then((res: any) => {
                      const data = res.data;
                      wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: data.appid, // 必填，公众号的唯一标识
                        timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                        nonceStr: data.nonceStr, // 必填，生成签名的随机串
                        signature: data.str, // 必填，签名
                        jsApiList: [
                          "checkJsApi",
                          "updateAppMessageShareData",
                          "updateTimelineShareData",
                        ], // 必填，需要使用的JS接口列表
                      });
                      wx.ready(function () {
                        //需在用户可能点击分享按钮前就先调用

                        wx.updateAppMessageShareData({
                          title: to+'-伟业计量', // 分享标题
                          desc: route.meta.content.description, // 分享描述
                          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                          imgUrl: (productsVO.value as any).pic || "http://m.bzwz.com/img/wxshare/logo-weiyegg.png", // 分享图标
                          success: function () {
                            // 设置成功
                          },
                        });
                        wx.updateTimelineShareData({
                          title: to+'-伟业计量', // 分享标题
                          desc: route.meta.content.description, // 分享描述
                          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                          imgUrl: (productsVO.value as any).pic || "http://m.bzwz.com/img/wxshare/logo-weiyegg.png", // 分享图标
                          success: function () {
                            // 设置成功
                          },
                        });
                      });
                    });
                  });
                }
              }
      )
      const loadOption = {
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
      };
      if(localStorage.getItem('WX')){
        setTimeout(function () {
          location.reload();
        },1000)
      }
      localStorage.removeItem('WX')
      return {
        productsVO,
        list,
        amclass,
        comp,
        id,
        classId,
        isFav,
        RelatedProIdList,
        standardList,
        loadOption,
        secListComp,
        listCompLen,
        productLotList,
        clickSpec,
        specChecked,
        firstSpec,
        lotid,
        tcid,
        productPrice,
        originalPrice,
        isLogin,
        biaowuPriceOpen,
        productPdfHref,
        productStock,
        videoFileId,
        shopShow,
        isApplyOpen,
        showModal,
        calendarFormat,
        downloadcertificate,
        closeModal,
        download,
        downloadCert,
        catNo,
        suppliercode,
        batchNo,
        loadOptionSwitch
      };
    },
  });

</script>

<style>
  #player-container-id{
    width: 375px;
    height: 375px;
  }
  .wrong-content p {
    text-indent: 10px;
    margin-top: 10px;
  }

  .wrong-content table {
    width: 355.013px;
    border: none;
    margin: 0 auto;
    /*margin-top: 19.988px;*/
    border-collapse: collapse;
    border-color: #ccc;
  }

  .wrong-content thead {
    width: 355.013px;
    background-color: #df0024;
    border: 1px solid #df0024;
  }

  .wrong-content th {
    color: #fff;
    text-align: left;
    height: 30px;
    box-sizing: border-box;
    padding: 0 9px;
    line-height: 30px;
    font-size: 13.988px;
  }
  .wrong-content td {
    font-size: 13.988px;
    color: #666666;
    padding: 10.013px;
    word-break: break-word;
  }
  .wrong-content h3{
    padding: 0.02rem 0;
    font-weight: bold;
  }
</style>

<style lang="scss" scoped>
  video {
    width: 100%;
  }
  .product-wrap {
    padding-bottom: 50px;
  }
  .title-top {
    margin-top: 40px;
  }
  .product-pic img {
    width: 375px;
    height: 375px;
    display: block;
  }

  .product-catno {
    padding: 0 10.013px;
    padding-top: 21.487px;
    color: #444444;
    font-size: 16px;
    position: relative;
    font-weight: bold;
  }

  .product-info {
    position: relative;
    padding: 10.013px;
    padding-top: 3px;
    // padding-top: 21.487px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 6px solid #f4f4f4;

    display: flex;
    justify-content: space-between;
  }

  .product-param {
    padding: 10px;
    padding-bottom: 0;
    padding-left: 0.35rem;
    font-size: 14px;
    color: #666;
    display: flex;
    flex-wrap: wrap;
  }

  .highlight {
    background: #ffeded;
    padding: 0 10px;
    // border: 0.02667rem solid red;
    border: 1px solid #ffeded;
    background: #ffeded;
    color: red;
  }

  .product-param>div {
    width: 50%;
    line-height: 0.55rem;
    padding: 0.08rem 0;
  }

  .product-guige{
    // min-height: 2.5rem;
    // height: auto;
    display: flex;
    flex-wrap: wrap;
    span {
      height: auto;
      word-break: break-all;
      word-wrap: break-word;
      line-height: 0.55rem;
      padding: 3px 0;
    }
  }
  .product-specifications{
    display: inline-block;
    width: 45%;
    font-size: 14px;
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
    margin-left: 0.35rem;
    border:1px solid #ddd;
    margin-bottom:0.3rem;
    float:left;
    color: #666666;
  }
  .product-active{
    /*border: 1px solid red;*/
    /*background: #ffeded;*/
    /*color: red;*/
    border:1px solid #df0024 !important;
    background:url("../assets/img/xuanzhong.png") no-repeat  right 100%;
  }
  .product-name {
    width: 255px;
    color: #444444;
    font-size: 16.012px;
    position: relative;
    font-weight: bold;
  }

  .product-price {
    font-size: 14px;
    // margin-top: 10px;
    color: #DF0024;
    width: 75px;
    align-self: flex-end;
    font-weight: bold;
    text-align: center;
  }

  .operation {
    position: absolute;
    right: 10.013px;
    top: 50%;
    transform: translateY(-50%);
  }
  .operation a {
    color: #cc3333;
    font-size: 15px;
    font-weight: 700;
  }

  .product-detail .title,
  .recommend-title {
    padding-left: 19.988px;
    font-size: 15px;
    color: #444;
    position: relative;
    width: 375px;
    box-sizing: border-box;
    height: 43.988px;
    line-height: 43.988px;
  }

  .recommend-title {
    margin-top: 0.5rem;
  }
  .product-detail .title::after,
  .recommend-title::after {
    content: "";
    position: absolute;
    width: 2px;
    left: 10.013px;
    top: 50%;
    height: 13.012px;
    background-color: #df0024;
    margin-top: -6.487px;
  }

  table {
    width: 355.013px;
    border: none;
    margin: 0 auto;
    /*margin-top: 19.988px;*/
    border-collapse: collapse;
    border-color: #ccc;
  }
  thead {
    width: 355.013px;
    background-color: #df0024;
    border: 1px solid #df0024;
  }
  th {
    color: #fff;
    text-align: left;
    height: 30px;
    box-sizing: border-box;
    padding: 0 9px;
    line-height: 30px;
    font-size: 13.988px;
  }
  td {
    font-size: 13.988px;
    color: #666666;
    padding: 10.013px;
    word-break: break-word;
  }

  .name {
    width: 117px;
    box-sizing: border-box;
    border-right-color: #e5e5e5;
    background-color: #f2f2f2;
    line-height: 25.013px;
  }
  .detail-name {
    // width: 49.987px;
    box-sizing: border-box;
  }
  .wrong-view {
    /*padding: 0 10px;*/
    color: #666;
    font-size: 14px;
    margin-top: 10px;
    .wrong-content{
      padding: 0 10px;
    }
  }

  .wrong-view .title{
    padding-left: 19.988px;
    font-size: 15px;
    color: #444;
    position: relative;
    width: 375px;
    box-sizing: border-box;
    height: 43.988px;
    line-height: 43.988px;
  }

  .wrong-view .title::after{
    content: "";
    position: absolute;
    width: 2px;
    left: 10.013px;
    top: 50%;
    height: 13.012px;
    background-color: #df0024;
    margin-top: -6.487px;
  }

  .other-view {
    margin-top: 19.988px;
    border-top: 1px solid #eee;
  }
  .other-view .title {
    text-align: justify;
    font-size: 16.012px;
    color: #666666;
    line-height: 25.013px;
    margin: 19.988px 10.013px;
    font-weight: bold;
  }

  .other-view .content .detail-title {
    width: 355.013px;
    margin: 0 auto;
    font-size: 16.012px;
    color: #444;
    position: relative;

    margin-top: 19.988px;
    box-sizing: border-box;
  }

  .other-view .content .detail-content {
    width: 355.013px;
    margin: 0 auto;
    font-size: 15px;
    color: #444;
    position: relative;

    box-sizing: border-box;
  }

  .pdt_detail_box {
    font-size: 16.012px;

    color: #444;
    width: 375px;
    box-sizing: border-box;
    width: 355.013px;
    margin: 0 auto;
  }

  .info-left {
    margin: 0 auto;
    text-align: center;
  }

  .productViews {
    width: 109.987px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #df0024;
    color: #fff;
    border-radius: 36px;
    margin: 0 auto;
    margin-top: 7.987px;
  }

  .other-view .content a {
    text-align: justify;
    font-size: 16.012px;
    line-height: 25.013px;
    display: block;
    color: #666666 !important;
    margin: 12.488px 10.013px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .recommend {
    width: 355.013px;
    text-align: left;
    margin: 0 auto;

    box-sizing: border-box;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    position: relative;
  }


  .recommend .title {
    padding-left: 0.53301rem;
    font-size: 0.4rem;
    color: #444;
    position: relative;
    box-sizing: border-box;
    height: 1.17301rem;
    line-height: 1.17301rem;
    border-bottom: 0.02667rem solid #f4f4f4;
    margin-top: 0.5rem;
  }
  .recommend .title::after {
    content: "";
    position: absolute;
    width: 0.05333rem;
    left: 0.26701rem;
    top: 50%;
    height: 0.34699rem;
    background-color: #df0024;
    margin-top: -0.17299rem;
  }
  .recommend li {
    position: relative;
  }

  .recommend li::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 10.013px;
    width: 4.988px;
    height: 4.988px;
    background: #7f7f7f;
    border-radius: 50%;
  }

  .recommend a {
    text-align: justify;
    font-size: 13.988px;
    line-height: 25.013px;
    display: block;
    color: #666666;
    margin: 10.013px 0;
    overflow: hidden;
    padding-left: 10.013px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .recommend_standard{
    border-bottom: 0px;
    padding-bottom: 0;
  }
  .recommend_standard a{
    border-bottom: 0px;
  }
  .recommend h3 {
    margin: 0;
    font-weight: normal;
  }

  .recommend-title {
    padding-left: 19.988px;
    font-size: 15px;
    color: #444;
    position: relative;
    width: 375px;
    box-sizing: border-box;
    height: 43.988px;
    line-height: 43.988px;
    border-bottom: 1px solid #f4f4f4;
  }

  .recommend-title {
    margin-top: 0.5rem;
  }
  .recommend-title::after {
    content: "";
    position: absolute;
    width: 2px;
    left: 10.013px;
    top: 50%;
    height: 13.012px;
    background-color: #df0024;
    margin-top: -6.487px;
  }
  .product_original_price{
    font-size: 14px;
    // margin-top: 10px;
    color: #05b3d2;
    width: 125px;
    align-self: flex-end;
    font-weight: bold;
    text-align: center;
    text-decoration: line-through;
  }
  .product-certificate{
    border:1px solid #df0024;
    color:#df0024;
  }
  .modal-backdrop{
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal-body {
    background: white;
    //border-radius: 0.21333rem;
    z-index: 99;
    width: 7.53333rem;
    height: 130px;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0.53333rem 0.13333rem;
    font-size: 0.37333rem;
  }
  .modal-body-title {
    text-align: center;
    position: relative;
    bottom: 0.26667rem;
  }
  .like-user-search{
    width:90%;
    margin:0 auto;
    padding: 0.13333rem;
  }
  .like-user-search input{
    width: 100%;
    height: 0.8rem;
    background-color: #ffffff;
    border: solid 0.02667rem #dddddd;
    padding: 0 0.26667rem;
    //border-radius: 0.37333rem;
    position: relative;
  }
  .coupon-button{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    margin: 0 auto;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 10px;
  }
  .coupon_btn{
    color: #fff;
    font-size: 0.37333rem;
    margin-top: 0.18667rem;
    background: #e00025;
    padding: 0.10667rem 0.4rem;
    //border-radius: 0.16rem;
  }
</style>
